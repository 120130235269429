//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { RestoreModel } from '@/modules/restore-items/restore-model';
import { RestorePermissions } from '@/modules/restore-items/restore-permissions';
import i18n from '@/vueI18n';
import moment from 'moment';
// import TableViewPage from '@/shared/components/table-view-page.vue';
import QrcodeVue from 'qrcode.vue'

const { fields } = RestoreModel;

export default {
  name: 'app-restore-list-table',

  components: {
    // [TableViewPage.name]: TableViewPage,
    QrcodeVue,
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      filter: '',
      selection: [],
      columns: [
        // {
        //   name: 'id',
        //   field: 'id',
        //   label: '#',
        //   align: 'center',
        //   format: (val) => `${val}`,
        //   required: true,
        //   // sortable: true,
        // },
        {
          name: 'name',
          field: 'itemDetails',
          label: 'entities.importer.fields.itemName',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'serialNumber',
          field: 'serialNumber',
          label: 'entities.importer.fields.serialNumber',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'itemType',
          field: 'itemType',
          label: 'entities.importer.fields.itemType',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'status',
          field: 'status',
          label: 'entities.importer.fields.status',
          align: 'center',
          required: true,
          // sortable: true,
        },
        // {
        //   name: 'exportedAt',
        //   field: 'exportedAt',
        //   label: i18n('entities.importer.fields.exportedAt'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        // {
        //   name: 'warrantyStatus',
        //   field: 'warrantyStatus',
        //   label: i18n('entities.importer.fields.warrantyStatus'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        // {
        //   name: 'activationDate',
        //   field: 'activationDate',
        //   label: i18n('entities.importer.fields.activationDate'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        // {
        //   name: 'expirationDate',
        //   field: 'expirationDate',
        //   label: i18n('entities.importer.fields.expirationDate'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        {
          name: 'restoredBy',
          field: 'restoredBy',
          label: 'entities.importer.fields.restoredBy',
          align: 'center',
          required: true,
          // sortable: true,
        },
        // {
        //   name: 'clientPhone',
        //   field: 'activatedBy',
        //   label: i18n('entities.importer.fields.clientPhone'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        {
          name: 'restoredAt',
          field: 'restoredAt',
          label: 'entities.importer.fields.restoredAt',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'time',
          field: 'time',
          label: 'common.time',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'notes',
          field: 'notes',
          label: 'entities.importer.fields.notes',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'qrCode',
          field: 'qrCode',
          label: 'entities.importer.fields.qrCode',
          align: 'center',
          required: true,
          // sortable: true,
        },
        // {
        //   name: 'action',
        //   field: 'action',
        //   label: i18n('common.actions'),
        //   align: 'center',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      rows: 'restore/list/rows',
      count: 'restore/list/count',
      // pagination: 'restore/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'restore/list/loading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'restore/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language(){
      return i18n.locale;
    },

    hasPermissionToEdit() {
      return new RestorePermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new RestorePermissions(this.currentUser)
        .destroy;
    },

    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
    statusColor() {
      return (state) => {
        let color 
        switch (state) {
          case 'inactive':
            color = '#bc8283'
            break;
          case 'active':
            color = '#2FA84F'
            break;
          case 'expired': 
            color = '#FD3018'
            break
          default:
            color = '#003A5D'
            break;
        }
        return color
      }
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'restore/list/doChangeSort',
      doChangePaginationCurrentPage: 'restore/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'restore/list/doChangePaginationPageSize',
      doMountTable: 'restore/list/doMountTable',
      doDestroy: 'restore/destroy/doDestroy',
      doDestroyAll: 'restore/destroy/doDestroyAll',
      updateQuantityByTransaction:'restore/destroy/updateQuantityByTransaction',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = RestoreModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      if(fieldName=='itemType'){
        return val.length ? this.i18n(`entities.importer.enumerators.itemType.${val[0].value}`) : 'ـــ';
      }
      if(fieldName=='status'){
        return val.length ? this.i18n(`entities.importer.enumerators.status.${val[0].value}`) : 'ـــ';
      }
    },
    presenterMap(row, fieldName) {
      const val = RestoreModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = RestoreModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(RestoreModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = RestoreModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.language).format('hh:mm a')
    },
    presenterClient(row, fieldName, key) {
      const val = RestoreModel.presenter(row, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'view':
          this.dialogType = 'view'
          break;
        case 'deleteAll':
          this.dialogType = 'deleteAll'
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },
    async doDestroyAllWithConfirm(ids) {
      try {
        await this.doDestroyAll(ids);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
    async doDestroyWithConfirm(id) {
      try {
        // await this.updateQuantityByTransaction({collectionName:this.selectedRow.itemType,docId:this.selectedRow.itemId,quantity:-1})
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
  },
};

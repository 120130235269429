import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class RestorePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.restoreRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.restoreRestore,
    );
    this.importAutocomplete = permissionChecker.match(
      Permissions.values.restoreAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.restoreCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.restoreEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.restoreDestroy,
    );
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RestoreListTable from '@/modules/restore-items/components/restore-list-table.vue';
import { RestorePermissions } from '@/modules/restore-items/restore-permissions';
import { mapGetters, mapActions } from 'vuex';
// import firebase from 'firebase/app';
import 'firebase/firestore'
import { routerAsync } from '@/app-module';
import i18n from '@/vueI18n'

export default {
  name: 'app-restore-list-page',

  components: {
    [RestoreListTable.name]: RestoreListTable,
  },

  async created() {
    // await firebase.firestore().collection('items').onSnapshot({ includeMetadataChanges: true }, async querySnapshot => {
    //    console.log(`Received query snapshot of size ${querySnapshot.size}`);
    //   this.doFilterInBackground();
    // }, err => {
    //    console.log(`Encountered error: ${err}`);
    // });
    // await firebase.firestore().collection('items').onSnapshot(async querySnapshot => {
    //    console.log(`Received query snapshot of size ${querySnapshot.size}`);
    //   this.doFilterInBackground();
    // }, err => {
    //    console.log(`Encountered error: ${err}`);
    // });
  },
  async mounted() {
    await this.doFilter();
  },

  data() {
    return {
      // confirm: false,
    };
  },
  computed: {
    ...mapGetters({
      rows: 'restore/list/rows',
      count: 'restore/list/count',
      loading: 'restore/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new RestorePermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('entities.restore.label') 
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'restore/list/doFetch',
      doFetchInBackground: 'restore/list/doFetchInBackground',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    // doOpenModal() {
    //   this.confirm = true
    // },
    routeTo() {
      routerAsync().push('/restore/scan');
    },
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = {}
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },
  },
};
